var count = 0;

function dial(dialVal){
    if (count < 11) {
      var num = $("#dialPadValue").val();
      var dialVal = num + dialVal;
      $("#dialPadValue").val(dialVal);
      count++
    }
}

function back(){
  var num = $("#dialPadValue").val();
  var changeNum = num.slice(0,-1);
  $("#dialPadValue").val(changeNum);
  count--;
}

